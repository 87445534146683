// PaymentSuccessAudioProgram.js
import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LayoutNoStripe from "../components/layoutNoStripe";
import {
  Article,
  ArticleHeader,
  ArticleSection,
} from "../styledComponents/article";
import { PasswordReset } from "../components/Auth0/passwordReset";
import {
  getManagementApiToken,
  checkIfUserExists,
  createAuth0User,
  assignAuth0Role,
} from "../components/Auth0/auth0Helpers";
import { getStripeSession } from "../components/Stripe/stripeHelpers";

const PaymentSuccessAudioProgram = ({ location }) => {
  const [customer, setCustomer] = useState({});
  const [customerFirstName, setCustomerFirstName] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [isSendingReset, setIsSendingReset] = useState(false);
  const [userExists, setUserExists] = useState(false);
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const handlePasswordReset = async (email) => {
    setIsSendingReset(true);
    try {
      const token = await getManagementApiToken();
      const response = await fetch(
        `https://${process.env.GATSBY_AUTH0_DOMAIN}/dbconnections/change_password`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            client_id: process.env.GATSBY_AUTH0_MGMT_CLIENT_ID,
            email: email,
            connection: "Username-Password-Authentication",
            redirect_uri: `${window.location.origin}/reset-success`,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send password reset email.");
      }

      setMessage("Password reset email sent! Please check your inbox.", "success");
    } catch (error) {
      setMessage(`Error: ${error.message}`, "error");
    } finally {
      setIsSendingReset(false);
    }
  };

  useEffect(() => {
    const processStripeSession = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const sessionId = urlParams.get("sessionID");

      if (!sessionId) {
        setMessage("Error: Session ID not found in URL.", "error");
        return;
      }

      try {
        console.log("getStripeSession:", typeof getStripeSession);
        const sessionData = await getStripeSession(sessionId);
        console.log('sessionData:', sessionData);
        if (sessionData.customer && sessionData.productsList) {
          setCustomer(sessionData.customer);
          const fullName = sessionData.customer.name || "";
          setCustomerFirstName(fullName.split(" ")[0]);

          const email = sessionData.customer.email;
          const token = await getManagementApiToken();
    
          // Check if the user exists and get their userId
          console.log("checkIfUserExists:", typeof checkIfUserExists);
          let userId = await checkIfUserExists(email, token);
    
          if (userId) {
            console.log("✅ User exists. User ID:", userId);
            setUserExists(true);
          } else {
            console.log("🚀 User does not exist - creating account.");
            setUserExists(false);
    
            // Create the user and get the new userId
            const newUser = await createAuth0User(
              email,
              fullName.split(" ")[0],
              fullName.split(" ")[1] || "",
              token
            );
            userId = newUser; // Assign the new userId
    
            // Wait for user propagation
            await new Promise((resolve) => setTimeout(resolve, 3000)); // 3-second delay
          }
    
          // Assign the role to the user
          const productType = sessionData.productsList[0]?.metadata?.auth0_role || "default";
          console.log(`🔄 Assigning role ${productType} to user ${userId}...`);
          await assignAuth0Role(userId, productType, token);
    
          console.log(`✅ Role ${productType} assigned successfully to user ${userId}`);
        }
      } catch (error) {
        console.error("❌ Error in processStripeSession:", error);
        setMessage(`Error: ${error.message}`, "error");
      }
    };

    if (isAuthenticated !== undefined) {
      processStripeSession();
    }
  }, [isAuthenticated]);

  return (
    <LayoutNoStripe displayNavBorder location={location}>
      <Article itemScope itemType="http://schema.org/Article">
        <ArticleHeader noMargin>
          <h1 itemProp="headline">Payment Success</h1>
        </ArticleHeader>
        <ArticleSection itemProp="articleBody">
          {message && <div className={`message ${messageType}`}>{message}</div>}
          <p>
            Thank you {customerFirstName || "Customer"} for your purchase! We are processing your order.
            You will receive an email from Tracy within the next 24 hours.
          </p>

          {!isAuthenticated && userExists ? (
            <div style={{ marginTop: "20px" }}>
              <p>Already have an account? Log in below:</p>
              <button
                onClick={() => loginWithRedirect()}
                style={{
                  backgroundColor: "#28a745",
                  color: "#fff",
                  padding: "10px 15px",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                Log In
              </button>
              <PasswordReset
                email={customer.email}
                isSendingReset={isSendingReset}
                onReset={() => handlePasswordReset(customer.email)}
              />
            </div>
          ) : (
            <PasswordReset
              email={customer.email}
              isSendingReset={isSendingReset}
              onReset={() => handlePasswordReset(customer.email)}
            />
          )}
        </ArticleSection>
      </Article>
    </LayoutNoStripe>
  );
};

export default PaymentSuccessAudioProgram;